import { type FunctionComponent, useEffect, useRef, useState } from "react"

import {
  ChevronDownIcon,
  ChevronRightIcon,
  MinusIcon,
  PlusIcon,
} from "@radix-ui/react-icons"
import { Box, Flex, Text, TextField } from "@radix-ui/themes"
import {
  type Row,
  type TreeNode,
  TreeState,
  TreeTable,
} from "cp-react-tree-table"
import {
  addChild as addChildToTree,
  generateData,
  removeChild as removeChildFromTree,
} from "./demo-data-gen"
import type { DemoDataItem } from "./demo-data-static"

import "./table.css"
import { Button } from "../button"

const GENERATED_CONTENT = generateData()
const Demo: FunctionComponent = () => {
  const [content, setContent] = useState(GENERATED_CONTENT)

  const [treeState, setTreeState] = useState<Readonly<TreeState<DemoDataItem>>>(
    TreeState.create<DemoDataItem>(content.data)
  )
  const treeTableRef = useRef<TreeTable<DemoDataItem>>(null)

  useEffect(() => {
    setTreeState(TreeState.create<DemoDataItem>(content.data))
  }, [content])

  const renderHeaderCell = (name: string, alignLeft = true) => {
    return () => {
      return (
        <Text className={alignLeft ? "align-left" : "align-right"}>{name}</Text>
      )
    }
  }

  const renderIndexCell = (row: Row<DemoDataItem>) => {
    return (
      <Flex
        align="center"
        onClick={row.toggleChildren}
        className="cursor-pointer gap-2 text-gray-12"
      >
        {row.metadata.hasChildren ? (
          row.$state.isExpanded ? (
            <ChevronDownIcon className="size-4" />
          ) : (
            <ChevronRightIcon className="size-4" />
          )
        ) : (
          <Box className="size-4" />
        )}
        <Flex style={{ paddingLeft: `${row.metadata.depth * 15}px` }}>
          <Text className={"font-bold"}>{row.data.name}</Text>
        </Flex>
      </Flex>
    )
  }

  const renderEditableCell = (row: Row<DemoDataItem>) => {
    return (
      <TextField.Root
        type="text"
        value={row.data.contact}
        variant="soft"
        color="gray"
        className="h-6"
        onChange={(event) => {
          // .updateData will notify the TreeTable instance to dispatch an onChange call with
          // with a new value which includes the patched row data. The change will be visible
          // if the new value is picked up and passed through TreeTable's value prop.
          row.updateData({
            ...row.data,
            contact: event.target.value,
          })
        }}
      />
    )
  }

  const renderActionsCell = (row: Row<DemoDataItem>) => {
    // Update the addChild and removeChild functions
    function addChild(id: string) {
      addChildToTree(id, content, setContent)
    }

    function removeChild(id: string) {
      removeChildFromTree(id, content, setContent)
    }

    return (
      <Flex>
        <Button
          color="green"
          variant="ghost"
          radius="full"
          className="mr-2 size-3"
          onClick={() => addChild(row.data.id)}
        >
          <PlusIcon className="size-3" />
        </Button>
        <Button
          color="red"
          variant="ghost"
          radius="full"
          onClick={() => removeChild(row.data.id)}
        >
          <MinusIcon className="size-3" />
        </Button>
      </Flex>
    )
  }

  const [nodeScheduledForFocus, setNodeScheduledForFocus] = useState<
    { node: TreeNode<DemoDataItem>; hasRendered: boolean } | undefined
  >()
  useEffect(() => {
    if (nodeScheduledForFocus != null) {
      if (nodeScheduledForFocus.hasRendered) {
        const currentRowModel = treeState.findRowModel(
          nodeScheduledForFocus.node
        )
        if (currentRowModel != null) {
          treeTableRef.current?.scrollTo(currentRowModel.$state.top)
        }
        setNodeScheduledForFocus(undefined)
      } else {
        setNodeScheduledForFocus((_) => ({
          node: nodeScheduledForFocus.node,
          hasRendered: true,
        }))
      }
    }
  }, [nodeScheduledForFocus, treeState])

  return (
    <TreeTable<DemoDataItem>
      className="demo-tree-table w-10/12"
      height={360}
      headerHeight={32}
      ref={treeTableRef}
      value={treeState}
      onChange={(newVal: TreeState<DemoDataItem>) => {
        setTreeState(newVal)
      }}
    >
      <TreeTable.Column
        renderCell={renderIndexCell}
        renderHeaderCell={renderHeaderCell("Name")}
        basis="180px"
        grow={0}
      />
      <TreeTable.Column
        renderCell={renderEditableCell}
        renderHeaderCell={renderHeaderCell("Contact person")}
      />
      <TreeTable.Column
        renderCell={renderActionsCell}
        renderHeaderCell={renderHeaderCell("Actions")}
      />
    </TreeTable>
  )
}

export default Demo
