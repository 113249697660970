import { Toaster } from "@incmixf/core"
import { Box, Flex } from "@radix-ui/themes"
import type { FC, ReactNode } from "react"

import { NavbarMain } from "./navbar"
import { Sidebar } from "./sidebar"

interface PageLayoutProps {
  children: ReactNode
}

export const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  return (
    <Flex direction="column" className="min-h-screen">
      <NavbarMain />
      <Flex className="flex-1">
        <Sidebar />
        <Box className={"flex-1 bg-gray-1 p-6 transition-all duration-300"}>
          {children}
        </Box>
      </Flex>
      <Toaster />
    </Flex>
  )
}
