import type { I18nTranslations } from "@incmixf/i18n/src/types/core"

export const ptTranslations: I18nTranslations = {
  profileImage: {
    error: {
      uploadProfilePicture: "Erro ao enviar foto de perfil",
      addProfilePicture: "Erro ao adicionar foto de perfil",
      deleteProfilePicture: "Erro ao deletar foto de perfil",
    },
    success: {
      uploadProfilePicture: "Foto de perfil enviada com sucesso",
      addProfilePicture: "Foto de perfil adicionada com sucesso",
      deleteProfilePicture: "Foto de perfil deletada com sucesso",
    },
  },
}
