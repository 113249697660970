import type { I18nTranslations } from "@incmixf/i18n/types/common"

export const ptTranslations: I18nTranslations = {
  common: {
    fullName: "Nome Completo",
    email: "Email",
    password: "Senha",
    loading: "Carregando...",
    back: "Voltar",
    you: "Você",
    remove: "Remover",
    confirm: "Confirmar",
    cancel: "Cancelar",
    verificationCode: "Código de Verificação",
  },
  pageNotFound: {
    title: "Página Não Encontrada",
    message: "A página que você está procurando não existe.",
    backToHome: "Voltar para a página inicial",
  },
  dbConnections: {
    dbConnections: "DB Connections",
    addNewConnection: "Add New Connection",
    connectionName: "Connection Name",
    connectionString: "Connection String",
    host: "Host",
    port: "Port",
    username: "Username",
    password: "Password",
    testConnection: "Test Connection",
    testingConnection: "Testing Connection...",
    connectionTestSuccess: "Connection test successful",
    connectionTestFailed: "Connection test failed",
    deleteConnection: "Delete Connection",
    deleteConfirmation: "Are you sure you want to delete {name}?",
    connectionNameRequired: "Connection name is required",
    connectionStringRequired: "Connection string is required",
    hostRequired: "Host is required",
    portRequired: "Port is required",
    usernameRequired: "Username is required",
    passwordRequired: "Password is required",
  },
}
