import "@incmixf/utils/src/styles/global.css"
import React from "react"
import ReactDOM from "react-dom/client"
import "./instrument"
import App from "./App"
import "@incmixf/i18n"
import QueryProvider from "./query-client"

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryProvider>
      <App />
    </QueryProvider>
  </React.StrictMode>
)
