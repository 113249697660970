import type { I18nTranslations } from "@incmixf/i18n/src/types/core"

export const enTranslations: I18nTranslations = {
  profileImage: {
    error: {
      uploadProfilePicture: "Error uploading profile picture",
      addProfilePicture: "Error adding profile picture",
      deleteProfilePicture: "Error deleting profile picture",
    },
    success: {
      uploadProfilePicture: "Profile picture uploaded successfully",
      addProfilePicture: "Profile picture added successfully",
      deleteProfilePicture: "Profile picture deleted successfully",
    },
  },
}
