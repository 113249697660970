import { LoadingPage } from "@common"
import {
  CardContainer,
  EditSwitch,
  SwapyExclude,
  SwapyLayout,
  SwapySlot,
} from "@incmixf/core"
import { ClockWidget } from "@incmixf/widgets/src/clock"
import { NewsWidget } from "@incmixf/widgets/src/news"
import { WeatherWidget } from "@incmixf/widgets/src/weather"
import { Box, Container, Flex, Heading, Text } from "@radix-ui/themes"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { createSwapy } from "swapy"
import { useAuth, useCurrentUser } from "../auth"
import { PageLayout } from "../common/components/layouts/page-layout"
import { UserProfileImage } from "../common/components/user-profile-image"

type Widget = {
  id: string
  type: "weather" | "clock" | "news"
}

type WelcomeCardProps = {
  onEditChange: (checked: boolean) => void
}

const MOCK_WEATHER_DATA = {
  lat: "40.730610",
  lon: "-73.935242",
}

const MOCK_CLOCK_DATA = [
  { city: "New York", timeZone: "America/New_York" },
  { city: "London", timeZone: "Europe/London" },
  { city: "Tokyo", timeZone: "Asia/Tokyo" },
]

const INITIAL_WIDGETS: Widget[] = [
  { id: "weather", type: "weather" },
  { id: "clock", type: "clock" },
  { id: "news", type: "news" },
]

const INITIAL_SLOT_ITEMS = [
  { slotId: "slot1", itemId: "weather" },
  { slotId: "slot2", itemId: "clock" },
  { slotId: "slot3", itemId: "news" },
]

const WelcomeCard: React.FC<WelcomeCardProps> = ({ onEditChange }) => {
  const { t } = useTranslation(["dashboard", "common"])
  const { user } = useCurrentUser()

  if (!user) return null

  return (
    <CardContainer>
      <Flex justify="between" align="center">
        <Flex align="center" gap="4">
          <UserProfileImage size="6" userId={user.id} />
          <Box>
            <Text size="2" color="gray">
              {t("dashboard:welcome")}
            </Text>
            <Heading size="4">{user.fullName}</Heading>
          </Box>
        </Flex>
        <Flex align="center" gap="2">
          <Text size="2" color="gray">
            {t("dashboard:editMode")}
          </Text>
          <EditSwitch onCheckedChange={onEditChange} />
        </Flex>
      </Flex>
    </CardContainer>
  )
}

const renderWidget = (widget: Widget) => {
  switch (widget.type) {
    case "weather":
      return <WeatherWidget location={MOCK_WEATHER_DATA} />
    case "clock":
      return <ClockWidget clocks={MOCK_CLOCK_DATA} size="2" />
    case "news":
      return <NewsWidget country="us" />
    default:
      return null
  }
}

const DashboardPage: React.FC = () => {
  const { t } = useTranslation(["dashboard", "common"])
  const { authUser, isLoading } = useAuth()
  const swapyRef = useRef<ReturnType<typeof createSwapy> | null>(null)

  const [isEditing, setIsEditing] = useState(false)
  const [widgets] = useState<Widget[]>(() => INITIAL_WIDGETS)
  const [slotItemsMap, setSlotItemsMap] = useState(() => INITIAL_SLOT_ITEMS)

  const slottedWidgets = useMemo(() => {
    return slotItemsMap.map(({ slotId, itemId }) => ({
      slotId,
      itemId,
      widget: widgets.find((w) => w.id === itemId),
    }))
  }, [widgets, slotItemsMap])

  useEffect(() => {
    const container = document.querySelector("#dashboard-container")
    if (!container) return

    swapyRef.current = createSwapy(container, {
      manualSwap: true,
      swapMode: "drop",
    })

    swapyRef.current.onSwap(({ data }) => {
      setSlotItemsMap(
        data.array.filter(
          (item): item is { slotId: string; itemId: string } =>
            item.itemId !== null
        )
      )
    })

    return () => {
      swapyRef.current?.destroy()
    }
  }, [])

  useEffect(() => {
    swapyRef.current?.setData({ array: slotItemsMap })
  }, [slotItemsMap])

  if (isLoading) return <LoadingPage />
  if (!authUser) return null

  return (
    <PageLayout>
      <Container size="4">
        <Flex direction="column" gap="6">
          <Heading size="6">{t("dashboard:title")}</Heading>

          <Flex direction="column" gap="6">
            <WelcomeCard onEditChange={setIsEditing} />

            <SwapyLayout
              id="dashboard-container"
              enable={isEditing}
              config={{ swapMode: "drop" }}
            >
              <Flex direction="row" gap="4" wrap="wrap">
                {slottedWidgets.map(({ slotId, widget }) => (
                  <SwapySlot key={slotId} id={slotId} showHandle={isEditing}>
                    {widget && (
                      <CardContainer>
                        <SwapyExclude id={widget.id}>
                          {renderWidget(widget)}
                        </SwapyExclude>
                      </CardContainer>
                    )}
                  </SwapySlot>
                ))}
              </Flex>
            </SwapyLayout>
          </Flex>
        </Flex>
      </Container>
    </PageLayout>
  )
}

export default DashboardPage
