import { createAbilityFromPermissions } from "@incmix/casl"
import type { AppAbility, Permission } from "@incmix/types/abilities"
import type {
  GetMembersResponse,
  Member,
  Organization,
} from "@incmix/types/organisations"
import { ORG_BASE_PATH } from "@incmixb/hono-shared/utils"
import { I18n } from "@incmixf/i18n"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "@tanstack/react-router"
import { toast } from "sonner"

const BFF_API_URL = import.meta.env["VITE_BFF_API_URL"]

const ORG_API_URL = `${BFF_API_URL}${ORG_BASE_PATH}`

export function useOrganizations() {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["organizations", I18n.language],
    queryFn: async () => {
      const res = await fetch(`${ORG_API_URL}/user`, {
        credentials: "include",
        headers: {
          "Accept-Language": I18n.language,
        },
      })
      if (!res.ok) throw new Error(I18n.t("error.fetchOrganizations"))
      return res.json()
    },
    retry: false,
  })

  return { organizations: data as Organization[], isLoading, isError }
}

export function useCreateOrganization() {
  type BuildingOrgMember = Omit<Member, "orgId">

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const createOrgMutation = useMutation({
    mutationFn: async ({
      name,
      members,
    }: {
      name: string
      members: BuildingOrgMember[]
    }) => {
      const response = await fetch(`${ORG_API_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": I18n.language,
        },
        body: JSON.stringify({ name, members }),
        credentials: "include",
      })
      if (!response.ok) {
        const data = (await response.json()) as any
        throw new Error(data.message || I18n.t("error.createOrganization"))
      }
      return response.json()
    },
    onSuccess: (data: any) => {
      queryClient.invalidateQueries({ queryKey: ["organizations"] })
      navigate({ to: `/organization/${data.id}` })
    },
    onError: (error) => {
      const message =
        error instanceof Error
          ? error.message
          : I18n.t("error.createOrganization")
      toast.error(message)
    },
  })

  const handleCreateOrganization = (
    name: string,
    members: BuildingOrgMember[]
  ) => {
    createOrgMutation.mutate({ name, members })
  }

  return {
    handleCreateOrganization,
    isCreatingOrganization: createOrgMutation.isPending,
    createOrganizationError: createOrgMutation.error,
  }
}

export function useUpdateOrganization() {
  const queryClient = useQueryClient()

  const updateOrgMutation = useMutation({
    mutationFn: async ({ orgId, name }: { orgId: string; name: string }) => {
      const response = await fetch(`${ORG_API_URL}/${orgId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": I18n.language,
        },
        body: JSON.stringify({ name }),
        credentials: "include",
      })
      if (!response.ok) {
        const errorData = (await response.json()) as any
        throw new Error(errorData.message || I18n.t("error.updateOrganization"))
      }
      return response.json()
    },
    onSuccess: (data: any) => {
      queryClient.setQueryData(["organizations", data.id], data)
      queryClient.invalidateQueries({ queryKey: ["organizations"] })
      queryClient.invalidateQueries({ queryKey: ["organization", data.id] })
    },
    onError: (error) => {
      const message =
        error instanceof Error
          ? error.message
          : I18n.t("error.updateOrganization")
      toast.error(message)
    },
  })

  const handleUpdateOrganization = (orgId: string, name: string) =>
    updateOrgMutation.mutateAsync({ orgId, name })

  return {
    handleUpdateOrganization,
    isUpdatingOrganization: updateOrgMutation.isPending,
    updateOrganizationError: updateOrgMutation.error,
  }
}

export function useDeleteOrganization() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const deleteOrgMutation = useMutation({
    mutationFn: async (orgId: string) => {
      const response = await fetch(`${ORG_API_URL}/${orgId}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Accept-Language": I18n.language,
        },
      })
      if (!response.ok) {
        const data = (await response.json()) as any
        throw new Error(data.message || I18n.t("error.deleteOrganization"))
      }
      return response.json()
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["organizations"] })
      navigate({ to: "/organizations" })
    },
    onError: (error) => {
      const message =
        error instanceof Error
          ? error.message
          : I18n.t("error.deleteOrganization")
      toast.error(message)
    },
  })

  const handleDeleteOrganization = (orgId: string) =>
    deleteOrgMutation.mutateAsync(orgId)

  return {
    handleDeleteOrganization,
    isDeletingOrganization: deleteOrgMutation.isPending,
    deleteOrganizationError: deleteOrgMutation.error,
  }
}

export function useAddMember() {
  const queryClient = useQueryClient()

  const addMemberMutation = useMutation({
    mutationFn: async ({
      orgId,
      email,
      role,
    }: {
      orgId: string
      email: string
      role: string
    }) => {
      const response = await fetch(`${ORG_API_URL}/${orgId}/members`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": I18n.language,
        },
        body: JSON.stringify({ email, role }),
        credentials: "include",
      })
      if (!response.ok) {
        const errorData = (await response.json()) as any
        throw new Error(errorData.message || I18n.t("error.addMember"))
      }
      return response.json()
    },
    onSuccess: (data: any) => {
      queryClient.setQueryData(["organizations", data.id], data)
      queryClient.invalidateQueries({ queryKey: ["organizations"] })
      queryClient.invalidateQueries({ queryKey: ["organization", data.id] })
      queryClient.invalidateQueries({
        queryKey: ["organizationMembers", data.id],
      })
    },
  })

  const handleAddMember = (orgId: string, email: string, role: string) =>
    addMemberMutation.mutateAsync({ orgId, email, role })

  return {
    handleAddMember,
    isAddingMember: addMemberMutation.isPending,
    addMemberError: addMemberMutation.error,
  }
}

export function useRemoveMembers() {
  const queryClient = useQueryClient()

  const removeMembersMutation = useMutation({
    mutationFn: async ({
      orgId,
      userIds,
    }: {
      orgId: string
      userIds: string[]
    }) => {
      const response = await fetch(`${ORG_API_URL}/${orgId}/members`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": I18n.language,
        },
        body: JSON.stringify({ userIds }),
        credentials: "include",
      })
      if (!response.ok) {
        const errorData = (await response.json()) as any
        throw new Error(errorData.message || I18n.t("error.removeMembers"))
      }
      return response.json()
    },
    onSuccess: (data: any) => {
      queryClient.setQueryData(["organizations", data.id], data)
      queryClient.invalidateQueries({ queryKey: ["organizations"] })
      queryClient.invalidateQueries({ queryKey: ["organization", data.id] })
      queryClient.invalidateQueries({
        queryKey: ["organizationMembers", data.id],
      })
    },
    onError: (error) => {
      const message =
        error instanceof Error ? error.message : I18n.t("error.removeMembers")
      toast.error(message)
    },
  })

  const handleRemoveMembers = (orgId: string, userIds: string[]) =>
    removeMembersMutation.mutateAsync({ orgId, userIds })

  return {
    handleRemoveMembers,
    isRemovingMembers: removeMembersMutation.isPending,
    removeMembersError: removeMembersMutation.error,
  }
}

export function useUpdateMemberRole() {
  const queryClient = useQueryClient()

  const updateMemberRoleMutation = useMutation({
    mutationFn: async ({
      orgId,
      userId,
      role,
    }: {
      orgId: string
      userId: string
      role: string
    }) => {
      const response = await fetch(`${ORG_API_URL}/${orgId}/members`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": I18n.language,
        },
        body: JSON.stringify({ userId, role }),
        credentials: "include",
      })
      if (!response.ok) {
        const errorData = (await response.json()) as any
        throw new Error(errorData.message || I18n.t("error.updateMemberRole"))
      }
      return response.json()
    },
    onSuccess: (data: any) => {
      queryClient.setQueryData(["organizations", data.id], data)
      queryClient.invalidateQueries({ queryKey: ["organizations"] })
      queryClient.invalidateQueries({ queryKey: ["organization", data.id] })
      queryClient.invalidateQueries({
        queryKey: ["organizationMembers", data.id],
      })
    },
  })

  const handleUpdateMemberRole = (
    orgId: string,
    userId: string,
    role: string
  ) => updateMemberRoleMutation.mutateAsync({ orgId, userId, role })

  return {
    handleUpdateMemberRole,
    isUpdatingMemberRole: updateMemberRoleMutation.isPending,
    updateMemberRoleError: updateMemberRoleMutation.error,
  }
}

export function useOrganization(orgId: string | undefined) {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["organization", orgId, I18n.language],
    queryFn: async () => {
      if (!orgId) throw new Error(I18n.t("error.organizationIdRequired"))
      const res = await fetch(`${ORG_API_URL}/id/${orgId}`, {
        credentials: "include",
        headers: {
          "Accept-Language": I18n.language,
        },
      })
      if (!res.ok) throw new Error(I18n.t("error.fetchOrganizationDetails"))
      return res.json()
    },
    enabled: !!orgId,
    retry: false,
  })

  return { organization: data as Organization | undefined, isLoading, isError }
}

export function useOrganizationMembers(orgId: string | undefined): {
  members: GetMembersResponse
  isLoading: boolean
  isError: boolean
} {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["organizationMembers", orgId, I18n.language],
    queryFn: async () => {
      if (!orgId) throw new Error(I18n.t("error.organizationIdRequired"))
      const res = await fetch(`${ORG_API_URL}/${orgId}/members`, {
        credentials: "include",
        headers: {
          "Accept-Language": I18n.language,
        },
      })
      if (!res.ok) throw new Error(I18n.t("error.fetchOrganizationMembers"))
      return res.json()
    },
    enabled: !!orgId,
    retry: false,
  })
  const members: GetMembersResponse = data as GetMembersResponse

  return { members, isLoading, isError }
}

const getOrganizationPermissions = async (
  orgId: string
): Promise<Permission[]> => {
  const response = await fetch(`${ORG_API_URL}/${orgId}/permissions`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": I18n.language,
    },
  })

  if (!response.ok) {
    throw new Error("Failed to fetch organization permissions")
  }

  return response.json() as Promise<Permission[]>
}

export const useOrganizationMemberAbility = (
  orgId: string
): {
  ability: AppAbility | undefined
  isLoading: boolean
  isError: boolean
} => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["organizationPermissions", orgId],
    queryFn: () => getOrganizationPermissions(orgId),
  })

  if (!data) return { ability: undefined, isLoading, isError }

  const ability = createAbilityFromPermissions(data)

  return { ability, isLoading, isError }
}
