import {
  FluentProvider,
  webDarkTheme,
  webLightTheme,
} from "@fluentui/react-components"
import { I18n, usei18n } from "@incmixf/i18n"
import LoadingPage from "@incmixf/pages/src/common/loading-page"
import { useLanguageStore, useThemeStore } from "@incmixf/store"
import { Theme } from "@radix-ui/themes"
import { useQuery } from "@tanstack/react-query"
import { RouterProvider } from "@tanstack/react-router"
import React, { Suspense, useEffect } from "react"
import { router } from "./instrument"
import QueryProvider from "./query-client"
import { translations } from "./translations"

function App() {
  const { theme } = useThemeStore()
  const { language } = useLanguageStore()
  useQuery({
    queryKey: ["translations"],
    queryFn: () => {
      usei18n(translations)
      return ""
    },
  })
  // useEffect(() => {
  //   usei18n(translations)
  // }, [])

  useEffect(() => {
    if (language) {
      I18n.changeLanguage(language)
    }
  }, [language])

  return (
    <Theme
      accentColor="blue"
      grayColor="slate"
      panelBackground="solid"
      scaling="100%"
      radius="large"
      appearance={theme}
    >
      <Suspense fallback={<LoadingPage />}>
        <FluentProvider
          theme={theme === "light" ? webLightTheme : webDarkTheme}
        >
          <RouterProvider router={router} />
        </FluentProvider>
      </Suspense>
    </Theme>
  )
}

export default App
