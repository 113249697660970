import { LoadingPage } from "@common"
import type { Organization } from "@incmix/types/organisations"
import { Button, CardContainer } from "@incmixf/core"
import { ChevronRightIcon } from "@radix-ui/react-icons"
import {
  Container,
  Dialog,
  Flex,
  Heading,
  Table,
  Text,
  TextField,
} from "@radix-ui/themes"
import { Link } from "@tanstack/react-router"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuth } from "../auth"
import { PageLayout } from "../common/components/layouts/page-layout"
import { useCreateOrganization, useOrganizations } from "./utils"

const OrganizationHeader: React.FC<{ onCreateClick: () => void }> = ({
  onCreateClick,
}) => {
  const { t } = useTranslation(["organizations"])
  return (
    <Flex justify="between" align="center">
      <Heading size="6">{t("organizations:title")}</Heading>
      <Button onClick={onCreateClick}>
        {t("organizations:createOrganization")}
      </Button>
    </Flex>
  )
}

const OrganizationRow: React.FC<{ org: Organization }> = ({ org }) => {
  return (
    <Table.Row key={org.id} style={{ cursor: "pointer" }}>
      <Table.Cell>
        <Link
          to={`/organization/${org.id}`}
          style={{ textDecoration: "none", color: "inherit", display: "block" }}
        >
          {org.name}
        </Link>
      </Table.Cell>
      <Table.Cell>
        <Link
          to={`/organization/${org.id}`}
          style={{ textDecoration: "none", color: "inherit", display: "block" }}
        >
          {org.members.length}
        </Link>
      </Table.Cell>
      <Table.Cell>
        <Link
          to={`/organization/${org.id}`}
          style={{ textDecoration: "none", color: "inherit", display: "block" }}
        >
          <Flex justify="end">
            <ChevronRightIcon />
          </Flex>
        </Link>
      </Table.Cell>
    </Table.Row>
  )
}

const CreateOrganizationDialog: React.FC<{
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  newOrgName: string
  setNewOrgName: (name: string) => void
  onCreateOrganization: () => void
  isCreating: boolean
}> = ({
  isOpen,
  onOpenChange,
  newOrgName,
  setNewOrgName,
  onCreateOrganization,
  isCreating,
}) => {
  const { t } = useTranslation(["organizations"])
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.Title>{t("organizations:createNewOrganization")}</Dialog.Title>
        <Flex direction="column" gap="2">
          <TextField.Root
            placeholder={t("organizations:organizationName")}
            value={newOrgName}
            onChange={(e) => setNewOrgName(e.target.value)}
          />
          <Button onClick={onCreateOrganization} disabled={isCreating}>
            {isCreating
              ? t("organizations:creating")
              : t("organizations:create")}
          </Button>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  )
}

const OrganizationsPage: React.FC = () => {
  const { t } = useTranslation(["organizations", "common"])
  const { organizations, isLoading, isError } = useOrganizations()
  const { authUser } = useAuth()
  const { handleCreateOrganization, isCreatingOrganization } =
    useCreateOrganization()

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [newOrgName, setNewOrgName] = useState("")

  const handleCreate = () => {
    if (newOrgName && authUser) {
      handleCreateOrganization(newOrgName, [])
      setIsCreateDialogOpen(false)
      setNewOrgName("")
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingPage />
    }

    if (isError) {
      return (
        <Text className="text-center text-lg">
          {t("organizations:errorLoading")}
        </Text>
      )
    }

    if (!organizations || organizations.length === 0) {
      return (
        <Text className="text-center text-lg">
          {t("organizations:noOrganizations")}
        </Text>
      )
    }

    return (
      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>
              {t("organizations:name")}
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>
              {t("organizations:members")}
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {organizations.map((org) => (
            <OrganizationRow key={org.id} org={org} />
          ))}
        </Table.Body>
      </Table.Root>
    )
  }

  return (
    <PageLayout>
      <Container>
        <CardContainer>
          <Flex direction="column" gap="4">
            <OrganizationHeader
              onCreateClick={() => setIsCreateDialogOpen(true)}
            />
            {renderContent()}
          </Flex>
        </CardContainer>
      </Container>

      <CreateOrganizationDialog
        isOpen={isCreateDialogOpen}
        onOpenChange={setIsCreateDialogOpen}
        newOrgName={newOrgName}
        setNewOrgName={setNewOrgName}
        onCreateOrganization={handleCreate}
        isCreating={isCreatingOrganization}
      />
    </PageLayout>
  )
}

export default OrganizationsPage
