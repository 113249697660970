import type { I18nTranslations } from "@incmixf/i18n/src/types/user"

export const enTranslations: I18nTranslations = {
  settings: {
    settings: "Settings",
    generalInfo: "General information",
    nameRequired: "Name is required",
    saving: "Saving...",
    saveChanges: "Save changes",
    changePassword: "Change password",
    currentPassword: "Current password",
    currentPasswordRequired: "Current password is required",
    newPassword: "New password",
    newPasswordLength: "New password must be at least 8 characters",
    confirmNewPassword: "Confirm new password",
    confirmPasswordRequired: "Please confirm your new password",
    organizations: "Organizations",
    organization: "Organization",
    languageSettings: "Language Settings",
    selectLanguage: "Select Language",
    sidebarSettings: "Sidebar Settings",
    minifySidebar: "Minify Sidebar",
    themeSettings: "Theme Settings",
    darkMode: "Dark Mode",
  },
  profile: {
    profileInformation: "Profile Information",
    logout: "Logout",
    error: {
      uploadProfilePicture: "Error uploading profile picture",
      addProfilePicture: "Error adding profile picture",
      updateUser: "Error updating user",
      deleteProfilePicture: "Error deleting profile picture",
      changePassword: "Error changing password",
    },
    success: {
      uploadProfilePicture: "Profile picture uploaded successfully",
      addProfilePicture: "Profile picture added successfully",
      updateUser: "User updated successfully",
      deleteProfilePicture: "Profile picture deleted successfully",
      changePassword: "Password changed successfully",
    },
  },
}
