import type { I18nTranslations } from "@incmixf/i18n/types/common"

export const enTranslations: I18nTranslations = {
  common: {
    loading: "Loading...",
    fullName: "Full Name",
    email: "Email",
    password: "Password",
    verificationCode: "Verification Code",
    back: "Back",
    you: "You",
    remove: "Remove",
    confirm: "Confirm",
    cancel: "Cancel",
  },
  pageNotFound: {
    title: "Page Not Found",
    message: "The page you are looking for does not exist.",
    backToHome: "Back to Home",
  },
  dbConnections: {
    dbConnections: "DB Connections",
    addNewConnection: "Add New Connection",
    connectionName: "Connection Name",
    connectionString: "Connection String",
    host: "Host",
    port: "Port",
    username: "Username",
    password: "Password",
    testConnection: "Test Connection",
    testingConnection: "Testing Connection...",
    connectionTestSuccess: "Connection test successful",
    connectionTestFailed: "Connection test failed",
    deleteConnection: "Delete Connection",
    deleteConfirmation: "Are you sure you want to delete {name}?",
    connectionNameRequired: "Connection name is required",
    connectionStringRequired: "Connection string is required",
    hostRequired: "Host is required",
    portRequired: "Port is required",
    usernameRequired: "Username is required",
    passwordRequired: "Password is required",
  },
}
